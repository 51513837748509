// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * NERd
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ChangeSnapshot
 */
export interface ChangeSnapshot {
    /**
     * 
     * @type {string}
     * @memberof ChangeSnapshot
     */
    from_version: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeSnapshot
     */
    to_version: string;
}
/**
 * 
 * @export
 * @interface EntityList
 */
export interface EntityList {
    /**
     * 
     * @type {string}
     * @memberof EntityList
     */
    text: string;
    /**
     * 
     * @type {Snapshot}
     * @memberof EntityList
     */
    snapshot: Snapshot;
    /**
     * 
     * @type {Array<SpacyEntity2>}
     * @memberof EntityList
     */
    entities: Array<SpacyEntity2>;
}
/**
 * 
 * @export
 * @interface HttpError
 */
export interface HttpError {
    /**
     * A description of the error
     * @type {string}
     * @memberof HttpError
     */
    status: string;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {any}
     * @memberof InlineObject
     */
    file?: any;
}
/**
 * 
 * @export
 * @interface NerCompare
 */
export interface NerCompare {
    /**
     * 
     * @type {string}
     * @memberof NerCompare
     */
    text_id: string;
    /**
     * 
     * @type {SpacyDocument1}
     * @memberof NerCompare
     */
    first: SpacyDocument1;
    /**
     * 
     * @type {SpacyDocument1}
     * @memberof NerCompare
     */
    second: SpacyDocument1;
}
/**
 * 
 * @export
 * @interface NerCompareResult
 */
export interface NerCompareResult {
    /**
     * 
     * @type {Snapshot}
     * @memberof NerCompareResult
     */
    first_snapshot: Snapshot;
    /**
     * 
     * @type {Snapshot}
     * @memberof NerCompareResult
     */
    second_snapshot: Snapshot;
    /**
     * 
     * @type {Array<NerCompare>}
     * @memberof NerCompareResult
     */
    results: Array<NerCompare>;
}
/**
 * 
 * @export
 * @interface RawText
 */
export interface RawText {
    /**
     * 
     * @type {string}
     * @memberof RawText
     */
    text: string;
}
/**
 * 
 * @export
 * @interface Register
 */
export interface Register {
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    plain_password?: string;
}
/**
 * 
 * @export
 * @interface RoleList
 */
export interface RoleList {
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleList
     */
    roles?: Array<string>;
}
/**
 * 
 * @export
 * @interface Snapshot
 */
export interface Snapshot {
    /**
     * 
     * @type {Date}
     * @memberof Snapshot
     */
    trained_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Snapshot
     */
    created_at?: Date;
    /**
     * 
     * @type {number}
     * @memberof Snapshot
     */
    semaphore?: number;
    /**
     * 
     * @type {{ [key: string]: Type; }}
     * @memberof Snapshot
     */
    types?: { [key: string]: Type; };
    /**
     * 
     * @type {number}
     * @memberof Snapshot
     */
    id: number;
}
/**
 * 
 * @export
 * @interface SnapshotInfo
 */
export interface SnapshotInfo {
    /**
     * 
     * @type {Snapshot}
     * @memberof SnapshotInfo
     */
    snapshot: Snapshot;
    /**
     * 
     * @type {number}
     * @memberof SnapshotInfo
     */
    corpus_size: number;
    /**
     * 
     * @type {number}
     * @memberof SnapshotInfo
     */
    trained: number;
    /**
     * 
     * @type {number}
     * @memberof SnapshotInfo
     */
    trained_distinct: number;
    /**
     * 
     * @type {number}
     * @memberof SnapshotInfo
     */
    available: number;
    /**
     * 
     * @type {number}
     * @memberof SnapshotInfo
     */
    available_distinct: number;
}
/**
 * 
 * @export
 * @interface SpacyDocument
 */
export interface SpacyDocument {
    /**
     * 
     * @type {Array<SpacyToken>}
     * @memberof SpacyDocument
     */
    tokens?: Array<SpacyToken>;
    /**
     * 
     * @type {Date}
     * @memberof SpacyDocument
     */
    _created_at?: Date;
    /**
     * 
     * @type {Array<SpacyEntity>}
     * @memberof SpacyDocument
     */
    ents?: Array<SpacyEntity>;
    /**
     * 
     * @type {Array<SpacySentence>}
     * @memberof SpacyDocument
     */
    sents?: Array<SpacySentence>;
    /**
     * 
     * @type {string}
     * @memberof SpacyDocument
     */
    text: string;
}
/**
 * 
 * @export
 * @interface SpacyDocument1
 */
export interface SpacyDocument1 {
    /**
     * 
     * @type {Array<SpacyToken1>}
     * @memberof SpacyDocument1
     */
    tokens?: Array<SpacyToken1>;
    /**
     * 
     * @type {Date}
     * @memberof SpacyDocument1
     */
    _created_at?: Date;
    /**
     * 
     * @type {Array<SpacyEntity1>}
     * @memberof SpacyDocument1
     */
    ents?: Array<SpacyEntity1>;
    /**
     * 
     * @type {Array<SpacySentence1>}
     * @memberof SpacyDocument1
     */
    sents?: Array<SpacySentence1>;
    /**
     * 
     * @type {string}
     * @memberof SpacyDocument1
     */
    text: string;
}
/**
 * 
 * @export
 * @interface SpacyEntity
 */
export interface SpacyEntity {
    /**
     * 
     * @type {string}
     * @memberof SpacyEntity
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof SpacyEntity
     */
    end: number;
    /**
     * 
     * @type {number}
     * @memberof SpacyEntity
     */
    start: number;
}
/**
 * 
 * @export
 * @interface SpacyEntity1
 */
export interface SpacyEntity1 {
    /**
     * 
     * @type {string}
     * @memberof SpacyEntity1
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof SpacyEntity1
     */
    end: number;
    /**
     * 
     * @type {number}
     * @memberof SpacyEntity1
     */
    start: number;
}
/**
 * 
 * @export
 * @interface SpacyEntity2
 */
export interface SpacyEntity2 {
    /**
     * 
     * @type {string}
     * @memberof SpacyEntity2
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof SpacyEntity2
     */
    end: number;
    /**
     * 
     * @type {number}
     * @memberof SpacyEntity2
     */
    start: number;
}
/**
 * 
 * @export
 * @interface SpacySentence
 */
export interface SpacySentence {
    /**
     * 
     * @type {number}
     * @memberof SpacySentence
     */
    end: number;
    /**
     * 
     * @type {number}
     * @memberof SpacySentence
     */
    start: number;
}
/**
 * 
 * @export
 * @interface SpacySentence1
 */
export interface SpacySentence1 {
    /**
     * 
     * @type {number}
     * @memberof SpacySentence1
     */
    end: number;
    /**
     * 
     * @type {number}
     * @memberof SpacySentence1
     */
    start: number;
}
/**
 * 
 * @export
 * @interface SpacyToken
 */
export interface SpacyToken {
    /**
     * 
     * @type {string}
     * @memberof SpacyToken
     */
    pos?: string;
    /**
     * 
     * @type {number}
     * @memberof SpacyToken
     */
    head?: number;
    /**
     * 
     * @type {number}
     * @memberof SpacyToken
     */
    end: number;
    /**
     * 
     * @type {number}
     * @memberof SpacyToken
     */
    start: number;
    /**
     * 
     * @type {string}
     * @memberof SpacyToken
     */
    dep?: string;
    /**
     * 
     * @type {string}
     * @memberof SpacyToken
     */
    tag?: string;
    /**
     * 
     * @type {number}
     * @memberof SpacyToken
     */
    id: number;
}
/**
 * 
 * @export
 * @interface SpacyToken1
 */
export interface SpacyToken1 {
    /**
     * 
     * @type {string}
     * @memberof SpacyToken1
     */
    pos?: string;
    /**
     * 
     * @type {number}
     * @memberof SpacyToken1
     */
    head?: number;
    /**
     * 
     * @type {number}
     * @memberof SpacyToken1
     */
    end: number;
    /**
     * 
     * @type {number}
     * @memberof SpacyToken1
     */
    start: number;
    /**
     * 
     * @type {string}
     * @memberof SpacyToken1
     */
    dep?: string;
    /**
     * 
     * @type {string}
     * @memberof SpacyToken1
     */
    tag?: string;
    /**
     * 
     * @type {number}
     * @memberof SpacyToken1
     */
    id: number;
}
/**
 * 
 * @export
 * @interface Text
 */
export interface Text {
    /**
     * 
     * @type {Date}
     * @memberof Text
     */
    created_at?: Date;
    /**
     * 
     * @type {Array<object>}
     * @memberof Text
     */
    trainings?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof Text
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof Text
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    grant_type?: string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    password: string;
}
/**
 * 
 * @export
 * @interface TopContributor
 */
export interface TopContributor {
    /**
     * 
     * @type {string}
     * @memberof TopContributor
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TopContributor
     */
    total_trainings: number;
}
/**
 * 
 * @export
 * @interface TrainText
 */
export interface TrainText {
    /**
     * 
     * @type {string}
     * @memberof TrainText
     */
    text_id: string;
    /**
     * 
     * @type {Snapshot}
     * @memberof TrainText
     */
    snapshot: Snapshot;
    /**
     * 
     * @type {SpacyDocument1}
     * @memberof TrainText
     */
    spacy_document: SpacyDocument1;
}
/**
 * 
 * @export
 * @interface Training
 */
export interface Training {
    /**
     * 
     * @type {Date}
     * @memberof Training
     */
    created_at?: Date;
    /**
     * 
     * @type {object}
     * @memberof Training
     */
    user_id: object;
    /**
     * 
     * @type {object}
     * @memberof Training
     */
    text_id: object;
    /**
     * 
     * @type {SpacyDocument}
     * @memberof Training
     */
    document: SpacyDocument;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface Type
 */
export interface Type {
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    color: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    total_trainings?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    roles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface UserCredentials
 */
export interface UserCredentials {
    /**
     * A temporary JWT
     * @type {string}
     * @memberof UserCredentials
     */
    access_token: string;
    /**
     * A refresh token
     * @type {string}
     * @memberof UserCredentials
     */
    refresh_token: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCredentials
     */
    roles?: Array<string>;
}
/**
 * 
 * @export
 * @interface UserPayload
 */
export interface UserPayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserPayload
     */
    roles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserPayload
     */
    plain_password?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof UserPayload
     */
    trainings?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof UserPayload
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPayload
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface ValueOnlyText
 */
export interface ValueOnlyText {
    /**
     * 
     * @type {string}
     * @memberof ValueOnlyText
     */
    value: string;
}
/**
 * 
 * @export
 * @interface Worker
 */
export interface Worker {
    /**
     * 
     * @type {string}
     * @memberof Worker
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Worker
     */
    snapshot: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate new access and refresh tokens with password grant_type
         * @param {Token} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessToken(token: Token, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling createAccessToken.');
            }
            const localVarPath = `/api/auth/token`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Token" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(token !== undefined ? token : {}) : (token || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh access token
         * @param {string} grant_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAccessToken(grant_type: string, options: any = {}): RequestArgs {
            // verify required parameter 'grant_type' is not null or undefined
            if (grant_type === null || grant_type === undefined) {
                throw new RequiredError('grant_type','Required parameter grant_type was null or undefined when calling refreshAccessToken.');
            }
            const localVarPath = `/api/auth/refresh`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (grant_type !== undefined) {
                localVarQueryParameter['grant_type'] = grant_type;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns user credentials to skip login on register
         * @summary Register a new user
         * @param {Register} register 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(register: Register, options: any = {}): RequestArgs {
            // verify required parameter 'register' is not null or undefined
            if (register === null || register === undefined) {
                throw new RequiredError('register','Required parameter register was null or undefined when calling registerUser.');
            }
            const localVarPath = `/api/auth/register`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Register" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(register !== undefined ? register : {}) : (register || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate new access and refresh tokens with password grant_type
         * @param {Token} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessToken(token: Token, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCredentials> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).createAccessToken(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Refresh access token
         * @param {string} grant_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAccessToken(grant_type: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCredentials> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).refreshAccessToken(grant_type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns user credentials to skip login on register
         * @summary Register a new user
         * @param {Register} register 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(register: Register, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCredentials> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).registerUser(register, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Generate new access and refresh tokens with password grant_type
         * @param {Token} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessToken(token: Token, options?: any) {
            return AuthApiFp(configuration).createAccessToken(token, options)(axios, basePath);
        },
        /**
         * 
         * @summary Refresh access token
         * @param {string} grant_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAccessToken(grant_type: string, options?: any) {
            return AuthApiFp(configuration).refreshAccessToken(grant_type, options)(axios, basePath);
        },
        /**
         * Returns user credentials to skip login on register
         * @summary Register a new user
         * @param {Register} register 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(register: Register, options?: any) {
            return AuthApiFp(configuration).registerUser(register, options)(axios, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Generate new access and refresh tokens with password grant_type
     * @param {Token} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createAccessToken(token: Token, options?: any) {
        return AuthApiFp(this.configuration).createAccessToken(token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Refresh access token
     * @param {string} grant_type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshAccessToken(grant_type: string, options?: any) {
        return AuthApiFp(this.configuration).refreshAccessToken(grant_type, options)(this.axios, this.basePath);
    }

    /**
     * Returns user credentials to skip login on register
     * @summary Register a new user
     * @param {Register} register 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public registerUser(register: Register, options?: any) {
        return AuthApiFp(this.configuration).registerUser(register, options)(this.axios, this.basePath);
    }

}


/**
 * CorpusApi - axios parameter creator
 * @export
 */
export const CorpusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ValueOnlyText} value_only_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewText(value_only_text: ValueOnlyText, options: any = {}): RequestArgs {
            // verify required parameter 'value_only_text' is not null or undefined
            if (value_only_text === null || value_only_text === undefined) {
                throw new RequiredError('value_only_text','Required parameter value_only_text was null or undefined when calling addNewText.');
            }
            const localVarPath = `/api/corpus/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ValueOnlyText" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(value_only_text !== undefined ? value_only_text : {}) : (value_only_text || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} text_id 
         * @param {SpacyDocument1} spacy_document1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTextTraining(text_id: string, spacy_document1: SpacyDocument1, options: any = {}): RequestArgs {
            // verify required parameter 'text_id' is not null or undefined
            if (text_id === null || text_id === undefined) {
                throw new RequiredError('text_id','Required parameter text_id was null or undefined when calling addTextTraining.');
            }
            // verify required parameter 'spacy_document1' is not null or undefined
            if (spacy_document1 === null || spacy_document1 === undefined) {
                throw new RequiredError('spacy_document1','Required parameter spacy_document1 was null or undefined when calling addTextTraining.');
            }
            const localVarPath = `/api/corpus/{text_id}/trainings`
                .replace(`{${"text_id"}}`, encodeURIComponent(String(text_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["trainer"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"SpacyDocument1" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(spacy_document1 !== undefined ? spacy_document1 : {}) : (spacy_document1 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorpus(page?: number, page_size?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/corpus/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (page_size !== undefined) {
                localVarQueryParameter['page_size'] = page_size;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} text_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorpusText(text_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'text_id' is not null or undefined
            if (text_id === null || text_id === undefined) {
                throw new RequiredError('text_id','Required parameter text_id was null or undefined when calling getCorpusText.');
            }
            const localVarPath = `/api/corpus/{text_id}`
                .replace(`{${"text_id"}}`, encodeURIComponent(String(text_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} text_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCorpusText(text_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'text_id' is not null or undefined
            if (text_id === null || text_id === undefined) {
                throw new RequiredError('text_id','Required parameter text_id was null or undefined when calling removeCorpusText.');
            }
            const localVarPath = `/api/corpus/{text_id}`
                .replace(`{${"text_id"}}`, encodeURIComponent(String(text_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} text_id 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainingsForText(text_id: string, page?: number, page_size?: number, options: any = {}): RequestArgs {
            // verify required parameter 'text_id' is not null or undefined
            if (text_id === null || text_id === undefined) {
                throw new RequiredError('text_id','Required parameter text_id was null or undefined when calling trainingsForText.');
            }
            const localVarPath = `/api/corpus/{text_id}/trainings`
                .replace(`{${"text_id"}}`, encodeURIComponent(String(text_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (page_size !== undefined) {
                localVarQueryParameter['page_size'] = page_size;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(file?: any, options: any = {}): RequestArgs {
            const localVarPath = `/api/corpus/upload`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CorpusApi - functional programming interface
 * @export
 */
export const CorpusApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ValueOnlyText} value_only_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewText(value_only_text: ValueOnlyText, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Text> {
            const localVarAxiosArgs = CorpusApiAxiosParamCreator(configuration).addNewText(value_only_text, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} text_id 
         * @param {SpacyDocument1} spacy_document1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTextTraining(text_id: string, spacy_document1: SpacyDocument1, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CorpusApiAxiosParamCreator(configuration).addTextTraining(text_id, spacy_document1, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorpus(page?: number, page_size?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Text>> {
            const localVarAxiosArgs = CorpusApiAxiosParamCreator(configuration).getCorpus(page, page_size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} text_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorpusText(text_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Text> {
            const localVarAxiosArgs = CorpusApiAxiosParamCreator(configuration).getCorpusText(text_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} text_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCorpusText(text_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CorpusApiAxiosParamCreator(configuration).removeCorpusText(text_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} text_id 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainingsForText(text_id: string, page?: number, page_size?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Training>> {
            const localVarAxiosArgs = CorpusApiAxiosParamCreator(configuration).trainingsForText(text_id, page, page_size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(file?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CorpusApiAxiosParamCreator(configuration).uploadFile(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CorpusApi - factory interface
 * @export
 */
export const CorpusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {ValueOnlyText} value_only_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewText(value_only_text: ValueOnlyText, options?: any) {
            return CorpusApiFp(configuration).addNewText(value_only_text, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} text_id 
         * @param {SpacyDocument1} spacy_document1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTextTraining(text_id: string, spacy_document1: SpacyDocument1, options?: any) {
            return CorpusApiFp(configuration).addTextTraining(text_id, spacy_document1, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorpus(page?: number, page_size?: number, options?: any) {
            return CorpusApiFp(configuration).getCorpus(page, page_size, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} text_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorpusText(text_id: string, options?: any) {
            return CorpusApiFp(configuration).getCorpusText(text_id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} text_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCorpusText(text_id: string, options?: any) {
            return CorpusApiFp(configuration).removeCorpusText(text_id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} text_id 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainingsForText(text_id: string, page?: number, page_size?: number, options?: any) {
            return CorpusApiFp(configuration).trainingsForText(text_id, page, page_size, options)(axios, basePath);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(file?: any, options?: any) {
            return CorpusApiFp(configuration).uploadFile(file, options)(axios, basePath);
        },
    };
};

/**
 * CorpusApi - object-oriented interface
 * @export
 * @class CorpusApi
 * @extends {BaseAPI}
 */
export class CorpusApi extends BaseAPI {
    /**
     * 
     * @param {ValueOnlyText} value_only_text 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorpusApi
     */
    public addNewText(value_only_text: ValueOnlyText, options?: any) {
        return CorpusApiFp(this.configuration).addNewText(value_only_text, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} text_id 
     * @param {SpacyDocument1} spacy_document1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorpusApi
     */
    public addTextTraining(text_id: string, spacy_document1: SpacyDocument1, options?: any) {
        return CorpusApiFp(this.configuration).addTextTraining(text_id, spacy_document1, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [page_size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorpusApi
     */
    public getCorpus(page?: number, page_size?: number, options?: any) {
        return CorpusApiFp(this.configuration).getCorpus(page, page_size, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} text_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorpusApi
     */
    public getCorpusText(text_id: string, options?: any) {
        return CorpusApiFp(this.configuration).getCorpusText(text_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} text_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorpusApi
     */
    public removeCorpusText(text_id: string, options?: any) {
        return CorpusApiFp(this.configuration).removeCorpusText(text_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} text_id 
     * @param {number} [page] 
     * @param {number} [page_size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorpusApi
     */
    public trainingsForText(text_id: string, page?: number, page_size?: number, options?: any) {
        return CorpusApiFp(this.configuration).trainingsForText(text_id, page, page_size, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorpusApi
     */
    public uploadFile(file?: any, options?: any) {
        return CorpusApiFp(this.configuration).uploadFile(file, options)(this.axios, this.basePath);
    }

}


/**
 * NerApi - axios parameter creator
 * @export
 */
export const NerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RawText} raw_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentSnapshotTextEntities(raw_text: RawText, options: any = {}): RequestArgs {
            // verify required parameter 'raw_text' is not null or undefined
            if (raw_text === null || raw_text === undefined) {
                throw new RequiredError('raw_text','Required parameter raw_text was null or undefined when calling currentSnapshotTextEntities.');
            }
            const localVarPath = `/api/ner/current/entities`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"RawText" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(raw_text !== undefined ? raw_text : {}) : (raw_text || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RawText} raw_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentSnapshotTextParse(raw_text: RawText, options: any = {}): RequestArgs {
            // verify required parameter 'raw_text' is not null or undefined
            if (raw_text === null || raw_text === undefined) {
                throw new RequiredError('raw_text','Required parameter raw_text was null or undefined when calling currentSnapshotTextParse.');
            }
            const localVarPath = `/api/ner/current/parse`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"RawText" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(raw_text !== undefined ? raw_text : {}) : (raw_text || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} first_snapshot 
         * @param {string} second_snapshot 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nerCompare(first_snapshot: string, second_snapshot: string, page?: number, page_size?: number, options: any = {}): RequestArgs {
            // verify required parameter 'first_snapshot' is not null or undefined
            if (first_snapshot === null || first_snapshot === undefined) {
                throw new RequiredError('first_snapshot','Required parameter first_snapshot was null or undefined when calling nerCompare.');
            }
            // verify required parameter 'second_snapshot' is not null or undefined
            if (second_snapshot === null || second_snapshot === undefined) {
                throw new RequiredError('second_snapshot','Required parameter second_snapshot was null or undefined when calling nerCompare.');
            }
            const localVarPath = `/api/ner/compare/{first_snapshot}/{second_snapshot}`
                .replace(`{${"first_snapshot"}}`, encodeURIComponent(String(first_snapshot)))
                .replace(`{${"second_snapshot"}}`, encodeURIComponent(String(second_snapshot)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (page_size !== undefined) {
                localVarQueryParameter['page_size'] = page_size;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {RawText} raw_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotTextEntities(snapshot_id: number, raw_text: RawText, options: any = {}): RequestArgs {
            // verify required parameter 'snapshot_id' is not null or undefined
            if (snapshot_id === null || snapshot_id === undefined) {
                throw new RequiredError('snapshot_id','Required parameter snapshot_id was null or undefined when calling snapshotTextEntities.');
            }
            // verify required parameter 'raw_text' is not null or undefined
            if (raw_text === null || raw_text === undefined) {
                throw new RequiredError('raw_text','Required parameter raw_text was null or undefined when calling snapshotTextEntities.');
            }
            const localVarPath = `/api/ner/{snapshot_id}/entities`
                .replace(`{${"snapshot_id"}}`, encodeURIComponent(String(snapshot_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"RawText" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(raw_text !== undefined ? raw_text : {}) : (raw_text || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {RawText} raw_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotTextParse(snapshot_id: number, raw_text: RawText, options: any = {}): RequestArgs {
            // verify required parameter 'snapshot_id' is not null or undefined
            if (snapshot_id === null || snapshot_id === undefined) {
                throw new RequiredError('snapshot_id','Required parameter snapshot_id was null or undefined when calling snapshotTextParse.');
            }
            // verify required parameter 'raw_text' is not null or undefined
            if (raw_text === null || raw_text === undefined) {
                throw new RequiredError('raw_text','Required parameter raw_text was null or undefined when calling snapshotTextParse.');
            }
            const localVarPath = `/api/ner/{snapshot_id}/parse`
                .replace(`{${"snapshot_id"}}`, encodeURIComponent(String(snapshot_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"RawText" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(raw_text !== undefined ? raw_text : {}) : (raw_text || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainNer(options: any = {}): RequestArgs {
            const localVarPath = `/api/ner/train`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["trainer"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NerApi - functional programming interface
 * @export
 */
export const NerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RawText} raw_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentSnapshotTextEntities(raw_text: RawText, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityList> {
            const localVarAxiosArgs = NerApiAxiosParamCreator(configuration).currentSnapshotTextEntities(raw_text, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RawText} raw_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentSnapshotTextParse(raw_text: RawText, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainText> {
            const localVarAxiosArgs = NerApiAxiosParamCreator(configuration).currentSnapshotTextParse(raw_text, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} first_snapshot 
         * @param {string} second_snapshot 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nerCompare(first_snapshot: string, second_snapshot: string, page?: number, page_size?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NerCompareResult> {
            const localVarAxiosArgs = NerApiAxiosParamCreator(configuration).nerCompare(first_snapshot, second_snapshot, page, page_size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {RawText} raw_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotTextEntities(snapshot_id: number, raw_text: RawText, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityList> {
            const localVarAxiosArgs = NerApiAxiosParamCreator(configuration).snapshotTextEntities(snapshot_id, raw_text, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {RawText} raw_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotTextParse(snapshot_id: number, raw_text: RawText, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainText> {
            const localVarAxiosArgs = NerApiAxiosParamCreator(configuration).snapshotTextParse(snapshot_id, raw_text, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainNer(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainText> {
            const localVarAxiosArgs = NerApiAxiosParamCreator(configuration).trainNer(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NerApi - factory interface
 * @export
 */
export const NerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {RawText} raw_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentSnapshotTextEntities(raw_text: RawText, options?: any) {
            return NerApiFp(configuration).currentSnapshotTextEntities(raw_text, options)(axios, basePath);
        },
        /**
         * 
         * @param {RawText} raw_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentSnapshotTextParse(raw_text: RawText, options?: any) {
            return NerApiFp(configuration).currentSnapshotTextParse(raw_text, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} first_snapshot 
         * @param {string} second_snapshot 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nerCompare(first_snapshot: string, second_snapshot: string, page?: number, page_size?: number, options?: any) {
            return NerApiFp(configuration).nerCompare(first_snapshot, second_snapshot, page, page_size, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {RawText} raw_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotTextEntities(snapshot_id: number, raw_text: RawText, options?: any) {
            return NerApiFp(configuration).snapshotTextEntities(snapshot_id, raw_text, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {RawText} raw_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotTextParse(snapshot_id: number, raw_text: RawText, options?: any) {
            return NerApiFp(configuration).snapshotTextParse(snapshot_id, raw_text, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainNer(options?: any) {
            return NerApiFp(configuration).trainNer(options)(axios, basePath);
        },
    };
};

/**
 * NerApi - object-oriented interface
 * @export
 * @class NerApi
 * @extends {BaseAPI}
 */
export class NerApi extends BaseAPI {
    /**
     * 
     * @param {RawText} raw_text 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NerApi
     */
    public currentSnapshotTextEntities(raw_text: RawText, options?: any) {
        return NerApiFp(this.configuration).currentSnapshotTextEntities(raw_text, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {RawText} raw_text 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NerApi
     */
    public currentSnapshotTextParse(raw_text: RawText, options?: any) {
        return NerApiFp(this.configuration).currentSnapshotTextParse(raw_text, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} first_snapshot 
     * @param {string} second_snapshot 
     * @param {number} [page] 
     * @param {number} [page_size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NerApi
     */
    public nerCompare(first_snapshot: string, second_snapshot: string, page?: number, page_size?: number, options?: any) {
        return NerApiFp(this.configuration).nerCompare(first_snapshot, second_snapshot, page, page_size, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} snapshot_id 
     * @param {RawText} raw_text 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NerApi
     */
    public snapshotTextEntities(snapshot_id: number, raw_text: RawText, options?: any) {
        return NerApiFp(this.configuration).snapshotTextEntities(snapshot_id, raw_text, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} snapshot_id 
     * @param {RawText} raw_text 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NerApi
     */
    public snapshotTextParse(snapshot_id: number, raw_text: RawText, options?: any) {
        return NerApiFp(this.configuration).snapshotTextParse(snapshot_id, raw_text, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NerApi
     */
    public trainNer(options?: any) {
        return NerApiFp(this.configuration).trainNer(options)(this.axios, this.basePath);
    }

}


/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a list of available roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles(options: any = {}): RequestArgs {
            const localVarPath = `/api/roles/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a list of available roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleList> {
            const localVarAxiosArgs = RolesApiAxiosParamCreator(configuration).listRoles(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Returns a list of available roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles(options?: any) {
            return RolesApiFp(configuration).listRoles(options)(axios, basePath);
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @summary Returns a list of available roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public listRoles(options?: any) {
        return RolesApiFp(this.configuration).listRoles(options)(this.axios, this.basePath);
    }

}


/**
 * SnapshotsApi - axios parameter creator
 * @export
 */
export const SnapshotsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Snapshot} snapshot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCorpusSnapshot(snapshot: Snapshot, options: any = {}): RequestArgs {
            // verify required parameter 'snapshot' is not null or undefined
            if (snapshot === null || snapshot === undefined) {
                throw new RequiredError('snapshot','Required parameter snapshot was null or undefined when calling createCorpusSnapshot.');
            }
            const localVarPath = `/api/snapshots/current`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Snapshot" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(snapshot !== undefined ? snapshot : {}) : (snapshot || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSnapshot(snapshot_id: number, options: any = {}): RequestArgs {
            // verify required parameter 'snapshot_id' is not null or undefined
            if (snapshot_id === null || snapshot_id === undefined) {
                throw new RequiredError('snapshot_id','Required parameter snapshot_id was null or undefined when calling deleteSnapshot.');
            }
            const localVarPath = `/api/snapshots/{snapshot_id}`
                .replace(`{${"snapshot_id"}}`, encodeURIComponent(String(snapshot_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceTrain(snapshot_id: number, options: any = {}): RequestArgs {
            // verify required parameter 'snapshot_id' is not null or undefined
            if (snapshot_id === null || snapshot_id === undefined) {
                throw new RequiredError('snapshot_id','Required parameter snapshot_id was null or undefined when calling forceTrain.');
            }
            const localVarPath = `/api/snapshots/{snapshot_id}/force-train`
                .replace(`{${"snapshot_id"}}`, encodeURIComponent(String(snapshot_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceUntrain(snapshot_id: number, options: any = {}): RequestArgs {
            // verify required parameter 'snapshot_id' is not null or undefined
            if (snapshot_id === null || snapshot_id === undefined) {
                throw new RequiredError('snapshot_id','Required parameter snapshot_id was null or undefined when calling forceUntrain.');
            }
            const localVarPath = `/api/snapshots/{snapshot_id}/force-untrain`
                .replace(`{${"snapshot_id"}}`, encodeURIComponent(String(snapshot_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSnapshot(options: any = {}): RequestArgs {
            const localVarPath = `/api/snapshots/current`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshot(snapshot_id: number, options: any = {}): RequestArgs {
            // verify required parameter 'snapshot_id' is not null or undefined
            if (snapshot_id === null || snapshot_id === undefined) {
                throw new RequiredError('snapshot_id','Required parameter snapshot_id was null or undefined when calling getSnapshot.');
            }
            const localVarPath = `/api/snapshots/{snapshot_id}`
                .replace(`{${"snapshot_id"}}`, encodeURIComponent(String(snapshot_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List available snapshots
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCorpusSnapshots(page?: number, page_size?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/snapshots`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (page_size !== undefined) {
                localVarQueryParameter['page_size'] = page_size;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SnapshotsApi - functional programming interface
 * @export
 */
export const SnapshotsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Snapshot} snapshot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCorpusSnapshot(snapshot: Snapshot, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Snapshot> {
            const localVarAxiosArgs = SnapshotsApiAxiosParamCreator(configuration).createCorpusSnapshot(snapshot, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSnapshot(snapshot_id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnapshotInfo> {
            const localVarAxiosArgs = SnapshotsApiAxiosParamCreator(configuration).deleteSnapshot(snapshot_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceTrain(snapshot_id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SnapshotsApiAxiosParamCreator(configuration).forceTrain(snapshot_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceUntrain(snapshot_id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SnapshotsApiAxiosParamCreator(configuration).forceUntrain(snapshot_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSnapshot(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnapshotInfo> {
            const localVarAxiosArgs = SnapshotsApiAxiosParamCreator(configuration).getCurrentSnapshot(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshot(snapshot_id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnapshotInfo> {
            const localVarAxiosArgs = SnapshotsApiAxiosParamCreator(configuration).getSnapshot(snapshot_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List available snapshots
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCorpusSnapshots(page?: number, page_size?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Snapshot>> {
            const localVarAxiosArgs = SnapshotsApiAxiosParamCreator(configuration).listCorpusSnapshots(page, page_size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SnapshotsApi - factory interface
 * @export
 */
export const SnapshotsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {Snapshot} snapshot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCorpusSnapshot(snapshot: Snapshot, options?: any) {
            return SnapshotsApiFp(configuration).createCorpusSnapshot(snapshot, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSnapshot(snapshot_id: number, options?: any) {
            return SnapshotsApiFp(configuration).deleteSnapshot(snapshot_id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceTrain(snapshot_id: number, options?: any) {
            return SnapshotsApiFp(configuration).forceTrain(snapshot_id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceUntrain(snapshot_id: number, options?: any) {
            return SnapshotsApiFp(configuration).forceUntrain(snapshot_id, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSnapshot(options?: any) {
            return SnapshotsApiFp(configuration).getCurrentSnapshot(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} snapshot_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshot(snapshot_id: number, options?: any) {
            return SnapshotsApiFp(configuration).getSnapshot(snapshot_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary List available snapshots
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCorpusSnapshots(page?: number, page_size?: number, options?: any) {
            return SnapshotsApiFp(configuration).listCorpusSnapshots(page, page_size, options)(axios, basePath);
        },
    };
};

/**
 * SnapshotsApi - object-oriented interface
 * @export
 * @class SnapshotsApi
 * @extends {BaseAPI}
 */
export class SnapshotsApi extends BaseAPI {
    /**
     * 
     * @param {Snapshot} snapshot 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnapshotsApi
     */
    public createCorpusSnapshot(snapshot: Snapshot, options?: any) {
        return SnapshotsApiFp(this.configuration).createCorpusSnapshot(snapshot, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} snapshot_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnapshotsApi
     */
    public deleteSnapshot(snapshot_id: number, options?: any) {
        return SnapshotsApiFp(this.configuration).deleteSnapshot(snapshot_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} snapshot_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnapshotsApi
     */
    public forceTrain(snapshot_id: number, options?: any) {
        return SnapshotsApiFp(this.configuration).forceTrain(snapshot_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} snapshot_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnapshotsApi
     */
    public forceUntrain(snapshot_id: number, options?: any) {
        return SnapshotsApiFp(this.configuration).forceUntrain(snapshot_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnapshotsApi
     */
    public getCurrentSnapshot(options?: any) {
        return SnapshotsApiFp(this.configuration).getCurrentSnapshot(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} snapshot_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnapshotsApi
     */
    public getSnapshot(snapshot_id: number, options?: any) {
        return SnapshotsApiFp(this.configuration).getSnapshot(snapshot_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary List available snapshots
     * @param {number} [page] 
     * @param {number} [page_size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnapshotsApi
     */
    public listCorpusSnapshots(page?: number, page_size?: number, options?: any) {
        return SnapshotsApiFp(this.configuration).listCorpusSnapshots(page, page_size, options)(this.axios, this.basePath);
    }

}


/**
 * TrainingsApi - axios parameter creator
 * @export
 */
export const TrainingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} training_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTraining(training_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'training_id' is not null or undefined
            if (training_id === null || training_id === undefined) {
                throw new RequiredError('training_id','Required parameter training_id was null or undefined when calling deleteTraining.');
            }
            const localVarPath = `/api/trainings/{training_id}`
                .replace(`{${"training_id"}}`, encodeURIComponent(String(training_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainingsApi - functional programming interface
 * @export
 */
export const TrainingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} training_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTraining(training_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Training> {
            const localVarAxiosArgs = TrainingsApiAxiosParamCreator(configuration).deleteTraining(training_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TrainingsApi - factory interface
 * @export
 */
export const TrainingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} training_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTraining(training_id: string, options?: any) {
            return TrainingsApiFp(configuration).deleteTraining(training_id, options)(axios, basePath);
        },
    };
};

/**
 * TrainingsApi - object-oriented interface
 * @export
 * @class TrainingsApi
 * @extends {BaseAPI}
 */
export class TrainingsApi extends BaseAPI {
    /**
     * 
     * @param {string} training_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public deleteTraining(training_id: string, options?: any) {
        return TrainingsApiFp(this.configuration).deleteTraining(training_id, options)(this.axios, this.basePath);
    }

}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete user by id
         * @param {string} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(user_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'user_id' is not null or undefined
            if (user_id === null || user_id === undefined) {
                throw new RequiredError('user_id','Required parameter user_id was null or undefined when calling deleteUser.');
            }
            const localVarPath = `/api/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of existing users
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(page?: number, page_size?: number, query?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/users/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (page_size !== undefined) {
                localVarQueryParameter['page_size'] = page_size;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets currently logged user\'s account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loggedUserDetails(options: any = {}): RequestArgs {
            const localVarPath = `/api/users/me`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myTrainings(page?: number, page_size?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/users/me/trainings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (page_size !== undefined) {
                localVarQueryParameter['page_size'] = page_size;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patches the user entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLoggedUserDetails(options: any = {}): RequestArgs {
            const localVarPath = `/api/users/me`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        top5(options: any = {}): RequestArgs {
            const localVarPath = `/api/users/top5`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patches the user entity
         * @param {string} user_id 
         * @param {UserPayload} user_payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(user_id: string, user_payload: UserPayload, options: any = {}): RequestArgs {
            // verify required parameter 'user_id' is not null or undefined
            if (user_id === null || user_id === undefined) {
                throw new RequiredError('user_id','Required parameter user_id was null or undefined when calling updateUser.');
            }
            // verify required parameter 'user_payload' is not null or undefined
            if (user_payload === null || user_payload === undefined) {
                throw new RequiredError('user_payload','Required parameter user_payload was null or undefined when calling updateUser.');
            }
            const localVarPath = `/api/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"UserPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(user_payload !== undefined ? user_payload : {}) : (user_payload || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets specific user account details by a given id
         * @param {string} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDetails(user_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'user_id' is not null or undefined
            if (user_id === null || user_id === undefined) {
                throw new RequiredError('user_id','Required parameter user_id was null or undefined when calling userDetails.');
            }
            const localVarPath = `/api/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} user_id 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTrainings(user_id: string, page?: number, page_size?: number, options: any = {}): RequestArgs {
            // verify required parameter 'user_id' is not null or undefined
            if (user_id === null || user_id === undefined) {
                throw new RequiredError('user_id','Required parameter user_id was null or undefined when calling userTrainings.');
            }
            const localVarPath = `/api/users/{user_id}/trainings`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (page_size !== undefined) {
                localVarQueryParameter['page_size'] = page_size;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete user by id
         * @param {string} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(user_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).deleteUser(user_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns a list of existing users
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(page?: number, page_size?: number, query?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).listUsers(page, page_size, query, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets currently logged user\'s account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loggedUserDetails(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).loggedUserDetails(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myTrainings(page?: number, page_size?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Training>> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).myTrainings(page, page_size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Patches the user entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLoggedUserDetails(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).patchLoggedUserDetails(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        top5(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopContributor>> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).top5(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Patches the user entity
         * @param {string} user_id 
         * @param {UserPayload} user_payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(user_id: string, user_payload: UserPayload, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).updateUser(user_id, user_payload, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets specific user account details by a given id
         * @param {string} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDetails(user_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).userDetails(user_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} user_id 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTrainings(user_id: string, page?: number, page_size?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Training>> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).userTrainings(user_id, page, page_size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete user by id
         * @param {string} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(user_id: string, options?: any) {
            return UsersApiFp(configuration).deleteUser(user_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Returns a list of existing users
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(page?: number, page_size?: number, query?: string, options?: any) {
            return UsersApiFp(configuration).listUsers(page, page_size, query, options)(axios, basePath);
        },
        /**
         * 
         * @summary Gets currently logged user\'s account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loggedUserDetails(options?: any) {
            return UsersApiFp(configuration).loggedUserDetails(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myTrainings(page?: number, page_size?: number, options?: any) {
            return UsersApiFp(configuration).myTrainings(page, page_size, options)(axios, basePath);
        },
        /**
         * 
         * @summary Patches the user entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLoggedUserDetails(options?: any) {
            return UsersApiFp(configuration).patchLoggedUserDetails(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        top5(options?: any) {
            return UsersApiFp(configuration).top5(options)(axios, basePath);
        },
        /**
         * 
         * @summary Patches the user entity
         * @param {string} user_id 
         * @param {UserPayload} user_payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(user_id: string, user_payload: UserPayload, options?: any) {
            return UsersApiFp(configuration).updateUser(user_id, user_payload, options)(axios, basePath);
        },
        /**
         * 
         * @summary Gets specific user account details by a given id
         * @param {string} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDetails(user_id: string, options?: any) {
            return UsersApiFp(configuration).userDetails(user_id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} user_id 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTrainings(user_id: string, page?: number, page_size?: number, options?: any) {
            return UsersApiFp(configuration).userTrainings(user_id, page, page_size, options)(axios, basePath);
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Delete user by id
     * @param {string} user_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(user_id: string, options?: any) {
        return UsersApiFp(this.configuration).deleteUser(user_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Returns a list of existing users
     * @param {number} [page] 
     * @param {number} [page_size] 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listUsers(page?: number, page_size?: number, query?: string, options?: any) {
        return UsersApiFp(this.configuration).listUsers(page, page_size, query, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Gets currently logged user\'s account details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public loggedUserDetails(options?: any) {
        return UsersApiFp(this.configuration).loggedUserDetails(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [page_size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public myTrainings(page?: number, page_size?: number, options?: any) {
        return UsersApiFp(this.configuration).myTrainings(page, page_size, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Patches the user entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public patchLoggedUserDetails(options?: any) {
        return UsersApiFp(this.configuration).patchLoggedUserDetails(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public top5(options?: any) {
        return UsersApiFp(this.configuration).top5(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Patches the user entity
     * @param {string} user_id 
     * @param {UserPayload} user_payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(user_id: string, user_payload: UserPayload, options?: any) {
        return UsersApiFp(this.configuration).updateUser(user_id, user_payload, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Gets specific user account details by a given id
     * @param {string} user_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userDetails(user_id: string, options?: any) {
        return UsersApiFp(this.configuration).userDetails(user_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} user_id 
     * @param {number} [page] 
     * @param {number} [page_size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userTrainings(user_id: string, page?: number, page_size?: number, options?: any) {
        return UsersApiFp(this.configuration).userTrainings(user_id, page, page_size, options)(this.axios, this.basePath);
    }

}


/**
 * WorkersApi - axios parameter creator
 * @export
 */
export const WorkersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkers(options: any = {}): RequestArgs {
            const localVarPath = `/api/workers/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangeSnapshot} change_snapshot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignWorker(change_snapshot: ChangeSnapshot, options: any = {}): RequestArgs {
            // verify required parameter 'change_snapshot' is not null or undefined
            if (change_snapshot === null || change_snapshot === undefined) {
                throw new RequiredError('change_snapshot','Required parameter change_snapshot was null or undefined when calling reassignWorker.');
            }
            const localVarPath = `/api/workers/reassign`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth2Password required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oAuth2Password", ["admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ChangeSnapshot" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(change_snapshot !== undefined ? change_snapshot : {}) : (change_snapshot || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkersApi - functional programming interface
 * @export
 */
export const WorkersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkers(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Worker>> {
            const localVarAxiosArgs = WorkersApiAxiosParamCreator(configuration).listWorkers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ChangeSnapshot} change_snapshot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignWorker(change_snapshot: ChangeSnapshot, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WorkersApiAxiosParamCreator(configuration).reassignWorker(change_snapshot, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkersApi - factory interface
 * @export
 */
export const WorkersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkers(options?: any) {
            return WorkersApiFp(configuration).listWorkers(options)(axios, basePath);
        },
        /**
         * 
         * @param {ChangeSnapshot} change_snapshot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignWorker(change_snapshot: ChangeSnapshot, options?: any) {
            return WorkersApiFp(configuration).reassignWorker(change_snapshot, options)(axios, basePath);
        },
    };
};

/**
 * WorkersApi - object-oriented interface
 * @export
 * @class WorkersApi
 * @extends {BaseAPI}
 */
export class WorkersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkersApi
     */
    public listWorkers(options?: any) {
        return WorkersApiFp(this.configuration).listWorkers(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {ChangeSnapshot} change_snapshot 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkersApi
     */
    public reassignWorker(change_snapshot: ChangeSnapshot, options?: any) {
        return WorkersApiFp(this.configuration).reassignWorker(change_snapshot, options)(this.axios, this.basePath);
    }

}


